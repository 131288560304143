<script setup lang="ts">
const variant = ref<'left' | 'right'>()

const panelStore = usePanelsStore()

const { panels } = storeToRefs(panelStore)

const translateClass = computed(() => {
  return variant.value === 'left' ? '-translate-x-[576px]' : 'translate-x-[576px]'
})

const classFn = classed('', {
  variants: {
    variant: {
      left: 'inset-y-0 left-0',
      right: 'inset-y-0 right-0',
    },
  },
  defaultVariants: {
    variant: 'right',
  },
})
</script>

<template>
  <div
    v-if="panels.size"
    class="insert fixed inset-0 inset-x-0 z-40"
  >
    <transition
      appear
      enter-active-class="duration-300 ease-out"
      enter-from-class="transform opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="duration-200 ease-in"
      leave-from-class="opacity-100"
      leave-to-class="transform opacity-0"
    >
      <div class="insert fixed inset-0 inset-x-0 z-50 bg-black-100/40"></div>
    </transition>

    <TransitionGroup
      appear
      enter-from-class="translate-x-[576px]"
      enter-active-class="duration-[400ms] ease-out"
      enter-to-class="translate-x-0"
      leave-from-class="translate-x-0"
      leave-active-class="duration-[400ms] ease-in"
      leave-to-class="translate-x-[576px]"
    >
      <div
        class="fixed right-0 z-50 h-full w-full max-w-[608px]"
        v-for="[key, value] in panels"
        :key="key"
      >
        <component
          :is="key"
          :key="key"
          v-bind="value.props?.props"
        />
      </div>
    </TransitionGroup>
  </div>
</template>
